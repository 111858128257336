import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const htmlEntities = str => {
  if (!str) return
  return String(str).replace(/&mdash;/g, "—")
}

const SEO = ({ bodyAttributes, description, lang, meta, title, seo }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={bodyAttributes}
      title={htmlEntities(seo.title) || title}
      meta={[
        {
          name: "description",
          content: htmlEntities(seo.metaDesc) || description,
        },
        {
          property: "og:title",
          content: htmlEntities(seo.opengraphTitle) || title,
        },
        {
          property: "og:description",
          content: htmlEntities(seo.opengraphDescription) || description,
        },
        {
          property: "og:image",
          content: seo.opengraphImage ? seo.opengraphImage.sourceUrl : "",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: title,
        },
        {
          name: "twitter:title",
          content: htmlEntities(seo.twitterTitle) || title,
        },
        {
          name: "twitter:description",
          content: htmlEntities(seo.twitterDescription) || description,
        },
        {
          name: "twitter:image",
          content: seo.twitterImage ? seo.twitterImage.sourceUrl : "",
        },
      ]
        .filter(item => item.content)
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  title: "Together — A full-service agency.",
  description:
    "An award-winning creative agency in Covent Garden, London. We design and build stand-out brands, websites and digital products for the technology industry.",
  seo: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  seo: PropTypes.object,
}

export default SEO
