import React, { PureComponent, createRef } from "react"
import Anime from "react-anime"

class CardTransition extends PureComponent {
  constructor(props) {
    super(props)

    this.card = this.props.data.exit.state.card

    if (!this.card) return null

    this.cardPos = this.card.getBoundingClientRect()
    this.el = createRef(null)
  }

  render() {
    const { data } = this.props
    const { top, left, height, width } = this.cardPos

    let to = {
      top,
      left,
      height,
      width,
    }

    if (data.transitionStatus === "entering") {
      const caseHero = document.querySelector(".case-hero")

      if (caseHero) {
        const bounds = caseHero.getBoundingClientRect()
        to = {
          top: bounds.top,
          left: bounds.left,
          height: bounds.height,
          width: bounds.width,
        }
      }
    }

    return (
      <div
        className="card-transition fixed h-screen w-screen top-0 left-0 bg-white"
        style={{ zIndex: "10000" }}
        ref={this.el}
      >
        <div
          style={{
            position: "relative",
            width: width + "px",
            height: height + "px",
          }}
        >
          <Anime
            easing="easeInOutExpo"
            complete={() => {
              if (this.el && this.el.current) {
                this.el.current.style.opacity = 0
              }
            }}
            duration={700}
            translateY={[top, to.top]}
            translateX={[left, to.left]}
            height={[height, to.height]}
            width={[width, to.width]}
            className="h-full w-full"
          >
            <div
              className="h-full w-full"
              style={{ backgroundColor: data.exit.state.backgroundColour }}
            ></div>
          </Anime>
        </div>
      </div>
    )
  }
}

export default CardTransition
